import axios from "axios";
import icsToJson from "ics-to-json";
import fileDownload from "react-file-download";

const url = "https://api.lacabanaberga.cat/api/";

const API_KEY = "AIzaSyAFzZAdnepKjFbfeIhbsgbrNorIjFzfK9o";

const Login = async (email, password) => {
    let message;
    await axios
        .post(url + "login", {
            email: email,
            password: password,
            remember_me: true,
        })
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("user", JSON.stringify(response.data));
                message = "Login amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "error";
            }
        })
        .catch((error) => {
            message = "error";
            console.log(error);
        });

    return message;
};

const getUser = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "user", config)
        .then((response) => {
            if (response.status === 200) {
                message = response.status;
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "401";
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
            }
        });
    return { message };
};

const logout = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let get_user;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "logout", config)
        .then((response) => {
            if (response.status === 200) {
                get_user = response.data;
                message = "400";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "401";
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                localStorage.setItem("isLoggedIn", false);
            }
        });
    return { get_user, message };
};

const calendaris = async (calendari) => {
    let calendaris;
    let url_cal = `https://www.googleapis.com/calendar/v3/calendars/${calendari}/events?key=${API_KEY}&timeMin=${new Date().toISOString()}`;
    await axios
        .get(url_cal)
        .then((response) => {
            calendaris = response.data.items;
        })
        .catch((error) => {});
    return calendaris;
};

const calendarisBooking = async (calendari) => {
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "response-type": "blob",
        },
    };
    let calendaris = "";
    await axios
        .get(url + "getBooking/" + calendari, config)
        .then((response) => {
            calendaris = response.data;
        })
        .catch((error) => {});
    return icsToJson(calendaris);
};

const createReserva = async (values) => {
    let form;

    const data = new FormData();

    data.append("dateS", values.dateS);
    data.append("dateF", values.dateF);
    data.append("habitacio_id", values.habitacio);
    data.append("preu", values.preu);
    data.append("extra", values.extra);
    data.append("news", values.news);
    data.append("lang", values.lang);
    data.append("nom_contacte", values.nom_contacte);
    data.append("email_contacte", values.email_contacte);
    data.append("phone_contacte", values.phone_contacte);

    if (values.factura?.rao) {
        data.append("rao", values.factura?.rao);
        data.append("nif", values.factura?.nif);
        data.append("adreca", values.factura?.adreca);
        data.append("codi_postal", values.factura?.codi_postal);
        data.append("pais", values.factura?.pais);
        data.append("poblacio", values.factura?.poblacio);
    }

    values.persones.map((item, index) => {
        data.append(`persona[${index}][nom]`, item?.nom);
        data.append(`persona[${index}][cognom1]`, item?.cognom1);
        data.append(`persona[${index}][cognom2]`, item?.cognom2 ?? "");
        if (!item?.menor) {
            data.append(`persona[${index}][email]`, values.email_contacte);
            data.append(`persona[${index}][naixement]`, item?.naixement);
            data.append(`persona[${index}][nacionalitat]`, item?.nacionalitat);
            data.append(`persona[${index}][dni]`, item?.dni);
            data.append(`persona[${index}][tipus]`, item?.tipus);
            data.append(`persona[${index}][caducitat]`, item?.caducitat);
            data.append(`persona[${index}][genere]`, item?.genere);
            if (item?.dniDavant) data.append(`persona[${index}][dniDavant]`, item?.dniDavant[0], item?.dniDavant[0].name);
            else {
                return "Error";
            }
            if (item?.dniDarrere) data.append(`persona[${index}][dniDarrere]`, item?.dniDarrere[0], item?.dniDarrere[0].name);
            else {
                return "Error";
            }
        }
    });

    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "reserva", data, config).then((response) => {
        if (response.status === 200) {
            form = response.data;
            message = "Reserva creada, esperi...";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return { message, form };
};

const checkin = async (values, unique_id) => {
    let form;

    const data = new FormData();

    values.persones.map((item, index) => {
        data.append(`persona[${index}][nom]`, item?.nom);
        data.append(`persona[${index}][cognom1]`, item?.cognom1);
        data.append(`persona[${index}][cognom2]`, item?.cognom2);
        if (!item?.menor) {
            data.append(`persona[${index}][email]`, values.email_contacte);
            data.append(`persona[${index}][naixement]`, item?.naixement);
            data.append(`persona[${index}][nacionalitat]`, item?.nacionalitat);
            data.append(`persona[${index}][dni]`, item?.dni);
            data.append(`persona[${index}][tipus]`, item?.tipus);
            data.append(`persona[${index}][caducitat]`, item?.caducitat);
            data.append(`persona[${index}][genere]`, item?.genere);
            if (item?.dniDavant) data.append(`persona[${index}][dniDavant]`, item?.dniDavant[0], item?.dniDavant[0].name);
            else {
                return "Error";
            }
            if (item?.dniDarrere) data.append(`persona[${index}][dniDarrere]`, item?.dniDarrere[0], item?.dniDarrere[0].name);
            else {
                return "Error";
            }
        }
    });

    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "checkin/" + unique_id, data, config).then((response) => {
        if (response.status === 200) {
            form = response.data;
            message = "Reserva creada, esperi...";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return { message, form };
};

const ferReserva = async (values) => {
    let message;
    let state;

    let config = {
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "ferReserva", values, config).then((response) => {
        if (response.status === 200) {
            message = "Reserva creada";
            state = 200;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
            state = 400;
        }
    });

    return { message, state };
};

const createReservaSenseCheckin = async (values) => {
    let message;
    let state;
    let form;

    let config = {
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "reservaSenseCheckin", values, config).then((response) => {
        if (response.status === 200) {
            form = response.data;
            message = "Reserva creada";
            state = 200;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
            state = 400;
        }
    });

    return { form, message, state };
};

const getReserva = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let reserva;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "reserva/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                reserva = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { reserva, message };
};

const obtenirPreus = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let preus;
    let preu_final;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "obtenirPreus/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                preus = response.data.preus;
                preu_final = response.data.preu_final;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { preus, preu_final, message };
};

const getAdmin = async (element) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let resultat;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + element, config)
        .then((response) => {
            if (response.status === 200) {
                resultat = response.data.data;
                message = "400";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "401";
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                localStorage.setItem("isLoggedIn", false);
            }
        });
    return { resultat, message };
};

const get = async (element) => {
    let message;
    let resultat;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios
        .get(url + element, config)
        .then((response) => {
            if (response.status === 200) {
                resultat = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { resultat, message };
};

const createHabitacio = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const newValues = { ...values, user_id: user.user_id };
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.post(url + "habitacions", newValues, config).then((response) => {
        if (response.status === 200) {
            message = "Habitació guardada amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear tanatori";
        }
    });

    return message;
};

const createReservaAdmin = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = new FormData();

    data.append("dateS", values.dateS);
    data.append("dateF", values.dateF);
    data.append("habitacio_id", values.habitacio);
    data.append("preu", values.preu);
    data.append("nom_contacte", values.nom_contacte);
    data.append("email_contacte", values.email_contacte);
    data.append("phone_contacte", values.phone_contacte);
    if (values.factura?.rao) {
        data.append("rao", values.factura?.rao);
        data.append("nif", values.factura?.nif);
        data.append("adreca", values.factura?.adreca);
        data.append("codi_postal", values.factura?.codi_postal);
        data.append("pais", values.factura?.pais);
        data.append("poblacio", values.factura?.poblacio);
    }

    values.persones.map((item, index) => {
        data.append(`persona[${index}][nom]`, item.nom);
        data.append(`persona[${index}][cognom1]`, item.cognom1);
        data.append(`persona[${index}][cognom2]`, item.cognom2);
        if (!item?.menor) {
            data.append(`persona[${index}][email]`, values.email_contacte);
            data.append(`persona[${index}][naixement]`, item?.naixement);
            data.append(`persona[${index}][nacionalitat]`, item?.nacionalitat);
            data.append(`persona[${index}][dni]`, item?.dni);
            data.append(`persona[${index}][tipus]`, item?.tipus);
            data.append(`persona[${index}][caducitat]`, item?.caducitat);
            data.append(`persona[${index}][genere]`, item?.genere);
            if (item?.dniDavant) data.append(`persona[${index}][dniDavant]`, item?.dniDavant[0], item?.dniDavant[0].name);
            else {
                return "Error";
            }
            if (item?.dniDarrere) data.append(`persona[${index}][dniDarrere]`, item?.dniDarrere[0], item?.dniDarrere[0].name);
            else {
                return "Error";
            }
        }
    });
    for (const value of data.values()) {
        console.log(value);
    }

    let message;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user.token,
        },
    };

    await axios.post(url + "reservaAdmin", data, config).then((response) => {
        if (response.status === 200) {
            message = "Reserva creada";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return { message };
};

const createReservaXec = async (values) => {
    const data = new FormData();

    data.append("dateS", values.dateS);
    data.append("dateF", values.dateF);
    data.append("habitacio_id", values.habitacio);
    data.append("preu", values.preu);
    data.append("codi", values.codi);
    data.append("nom_contacte", values.nom_contacte);
    data.append("email_contacte", values.email_contacte);
    data.append("phone_contacte", values.phone_contacte);

    if (values.factura?.rao) {
        data.append("rao", values.factura?.rao);
        data.append("nif", values.factura?.nif);
        data.append("adreca", values.factura?.adreca);
        data.append("codi_postal", values.factura?.codi_postal);
        data.append("pais", values.factura?.pais);
        data.append("poblacio", values.factura?.poblacio);
    }
    values.persones.map((item, index) => {
        data.append(`persona[${index}][nom]`, item.nom);
        data.append(`persona[${index}][cognom1]`, item.cognom1);
        data.append(`persona[${index}][cognom2]`, item.cognom2);
        if (!item?.menor) {
            data.append(`persona[${index}][email]`, values.email_contacte);
            data.append(`persona[${index}][naixement]`, item?.naixement);
            data.append(`persona[${index}][nacionalitat]`, item?.nacionalitat);
            data.append(`persona[${index}][dni]`, item?.dni);
            data.append(`persona[${index}][tipus]`, item?.tipus);
            data.append(`persona[${index}][caducitat]`, item?.caducitat);
            data.append(`persona[${index}][genere]`, item?.genere);
            if (item?.dniDavant) data.append(`persona[${index}][dniDavant]`, item?.dniDavant[0], item?.dniDavant[0].name);
            else {
                return "Error";
            }
            if (item?.dniDarrere) data.append(`persona[${index}][dniDarrere]`, item?.dniDarrere[0], item?.dniDarrere[0].name);
            else {
                return "Error";
            }
        }
    });

    let message;
    let reserva_id;

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "reservaXec", data, config).then((response) => {
        if (response.status === 200) {
            message = "Reserva creada";
            reserva_id = response.data.data;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return { message, reserva_id };
};

const updateHabitacio = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.put(url + "habitacions/" + key, values, config).then((response) => {
        if (response.status === 200) {
            message = "Habitació actualitzada amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar l'habitació";
        }
    });

    return message;
};

const updatePreus = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.put(url + "preus", values, config).then((response) => {
        if (response.status === 200) {
            message = "Preus actualitzats amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar els preus";
        }
    });

    return message;
};

const updateParams = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.put(url + "params", values, config).then((response) => {
        if (response.status === 200) {
            message = "Paràmetres actualitzats amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const changeState = async (values, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.put(url + "changeState/" + id, values, config).then((response) => {
        if (response.status === 200) {
            message = "Estat actualitzat";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const getPaisos = async () => {
    let message;
    let paisos;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios
        .get("https://restcountries.com/v3.1/all", config)
        .then((response) => {
            if (response.status === 200) {
                paisos = response.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { paisos, message };
};

const downloadTXT = async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let resultat;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "downloadTXT/" + id, config)
        .then((response) => {
            const ur = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = ur;
            link.setAttribute("download", "ID01254008.001.txt"); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
        });

    return { resultat, message };
};

const downloadPDF = async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let resultat;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "omplirDocumentMossos/" + id, config)
        .then((response) => {
            message = response.status;
            const link = document.createElement("a");
            link.href = response.data.url;
            link.setAttribute("download", "file.pdf");
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
        });

    return { resultat, message };
};

const devolucio = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let resultat;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .post(url + "retornar/" + key, values, config)
        .then((response) => {
            if (response.status === 200) {
                resultat = response.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { resultat, message };
};

const createExtra = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const newValues = { ...values, user_id: user.user_id };
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.post(url + "extras", newValues, config).then((response) => {
        if (response.status === 200) {
            message = "Extra creat amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear extra";
        }
    });

    return message;
};

const updateExtra = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.put(url + "extras/" + key, values, config).then((response) => {
        if (response.status === 200) {
            message = "Extra actualitzat amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const createXec = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const newValues = { ...values, user_id: user.user_id };
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.post(url + "xecs", newValues, config).then((response) => {
        if (response.status === 200) {
            message = "Habitació guardada amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear tanatori";
        }
    });

    return message;
};

const updateXec = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.put(url + "xecs/" + key, values, config).then((response) => {
        if (response.status === 200) {
            message = "Extra actualitzat amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const createCodi = async (values) => {
    let form;
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "codi", values, config).then((response) => {
        if (response.status === 200) {
            form = response.data;
            message = "Compra en procés, esperi...";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return { message, form };
};

const getCodi = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let codi;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "codi/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                codi = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { codi, message };
};

const changeStateXec = async (values, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.put(url + "changeStateXec/" + id, values, config).then((response) => {
        if (response.status === 200) {
            message = "Estat actualitzat";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const deleteElement = async (id, element) => {
    const user = JSON.parse(localStorage.getItem("user"));

    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.delete(url + element + "/" + id, config).then((response) => {
        if (response.status === 200) {
            message = "Eliminat amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al eliminar extra";
        }
    });

    return message;
};

const codiCorrecte = async (key) => {
    let message;
    let success;
    let codiG;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios
        .get(url + "comprovar/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                message = response.data.message;
                success = "success";
                codiG = response.data.data;
            } else if (response.status === 400) {
                message = response.data.message;
                success = "error";
            }
        })
        .catch((error) => {
            message = error?.response?.data.message;
            success = "error";
        });

    return { message, success, codiG };
};

const updatePersona = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios.put(url + "persones/" + key, values, config).then((response) => {
        if (response.status === 200) {
            message = "Habitació actualitzada amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar l'habitació";
        }
    });

    return message;
};

const comprovarDisponibilitat = async (reserva_id, habitacio_id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const values = { reserva_id: reserva_id, habitacio_id: habitacio_id };
    let solapat;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.post(url + "comprovarDisponibilitat", values, config).then((response) => {
        if (response.status === 200) {
            solapat = response.data.solapat;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            solapat = "Error al editar";
        }
    });

    return solapat;
};

const canviarHabitacio = async (reserva_id, habitacio_id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const values = { reserva_id: reserva_id, habitacio_id: habitacio_id };
    let missatge;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.post(url + "canviarHabitacio", values, config).then((response) => {
        if (response.status === 200) {
            missatge = response.data.missatge;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            missatge = "Error al canviar d'habitació";
        }
    });

    return missatge;
};

const downloadFactura = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            // Accept: "application/pdf",
            Authorization: "Bearer " + user?.token,
            responseType: "blob",
        },
    };
    await axios
        .get(url + "downloadFactura/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                message = response.status;
                const link = document.createElement("a");
                link.href = response.data.url;
                link.setAttribute("download", "file.pdf");
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "401";
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
            }
        });
    return { message };
};

const campsFactura = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let missatge;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.post(url + "campsFactura/" + key, values, config).then((response) => {
        if (response.status === 200) {
            missatge = response.data.missatge;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            missatge = "Error al obtenir dades";
        }
    });

    return missatge;
};

const addConcepte = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let missatge;

    const newsValues = {
        conceptes: values,
    };

    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "addConcepte/" + key, newsValues, config).then((response) => {
        if (response.status === 200) {
            missatge = response.data.missatge;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            missatge = "Error al canviar d'habitació";
        }
    });

    return missatge;
};

const checkinReserva = async (key) => {
    let message;
    let success;
    let reserva;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios
        .get(url + "checkinReserva/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                message = response.data.message;
                success = "success";
                reserva = response.data.data;
            } else if (response.status === 400) {
                message = response.data.message;
                success = "error";
            }
        })
        .catch((error) => {
            message = error?.response?.data.message;
            success = "error";
        });

    return { message, success, reserva };
};

const checkinAdmin = async (values, unique_id) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = new FormData();

    values.persones.map((item, index) => {
        data.append(`persona[${index}][nom]`, item?.nom);
        data.append(`persona[${index}][cognom1]`, item?.cognom1);
        data.append(`persona[${index}][cognom2]`, item?.cognom2);
        if (!item?.menor) {
            data.append(`persona[${index}][email]`, values.email_contacte);
            data.append(`persona[${index}][naixement]`, item?.naixement);
            data.append(`persona[${index}][nacionalitat]`, item?.nacionalitat);
            data.append(`persona[${index}][dni]`, item?.dni);
            data.append(`persona[${index}][tipus]`, item?.tipus);
            data.append(`persona[${index}][caducitat]`, item?.caducitat);
            data.append(`persona[${index}][genere]`, item?.genere);
            data.append(`persona[${index}][direccio]`, item?.direccio);
            data.append(`persona[${index}][poblacio]`, item?.poblacio);
            data.append(`persona[${index}][provincia]`, item?.provincia);
            data.append(`persona[${index}][genere]`, item?.provincia);
        }
    });

    let message;

    let config = {
        headers: {
            Authorization: "Bearer " + user.token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "checkinAdmin/" + unique_id, data, config).then((response) => {
        if (response.status === 200) {
            message = "Reserva creada, esperi...";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return { message };
};

const reenviarCorreus = async (key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "reenviarCorreus/" + key, config)
        .then((response) => {
            if (response.status === 200) {
                message = "E-mails enviats";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'ha trobat la reserva";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { message };
};

const updateDescompte = async (values, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.post(url + "updateDescompte/" + id, values, config).then((response) => {
        if (response.status === 200) {
            message = "Descompte actualitzat amb èxit";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar el descompte";
        }
    });

    return message;
};

const llegirDNI = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let data;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "llegirDNI", config)
        .then((response) => {
            if (response.status === 200) {
                data = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const comprovarPersonaAPI = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let data;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user?.token,
        },
    };
    await axios
        .get(url + "comprovarPersona", config)
        .then((response) => {
            if (response.status === 200) {
                data = response.data.data;
                message = "Obtingut amb èxit";
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const afegirPersonaAPI = async (values, key) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;

    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "afegirPersona/" + key, values, config).then((response) => {
        if (response.status === 200) {
            message = response.data.message;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al afegir persona";
        }
    });

    return message;
};

const checkinAdminLector = async (unique_id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;

    let config = {
        headers: {
            Authorization: "Bearer " + user.token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.get(url + "checkinAdminLector/" + unique_id, config).then((response) => {
        if (response.status === 200) {
            message = "Check-in fet";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al crear";
        }
    });

    return message;
};

const changeEmail = async (values, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.post(url + "changeEmail/" + id, values, config).then((response) => {
        if (response.status === 200) {
            message = "E-mail actualitzat";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const searchPersona = async (search) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let success;
    let persones;
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + user.token,
        },
    };
    await axios
        .get(url + "searchPersona/" + search, config)
        .then((response) => {
            if (response.status === 200) {
                message = response.data.message;
                success = "success";
                persones = response.data.data;
            } else if (response.status === 400) {
                message = response.data.message;
                success = "error";
            }
        })
        .catch((error) => {
            message = error?.response?.data.message;
            success = "error";
        });

    return { message, success, persones };
};

const changeNotes = async (values, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let message;
    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };
    await axios.post(url + "changeNotes/" + id, values, config).then((response) => {
        if (response.status === 200) {
            message = "Notes actualitzades";
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            message = "Error al editar";
        }
    });

    return message;
};

const addDia = async (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let missatge;
    let dies = [];

    let config = {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
            "Access-Control-Allow-Origin": "*",
        },
    };

    await axios.post(url + "dies", values, config).then((response) => {
        if (response.status === 200) {
            missatge = response.data.message;
            dies = response.data.data;
        }
        if (response.data.status === "failed" && response.data.success === undefined) {
            missatge = "Error al crear la configuració";
        }
    });

    return { missatge, dies };
};

export {
    Login,
    getUser,
    logout,
    calendaris,
    createReserva,
    getReserva,
    get,
    getAdmin,
    createHabitacio,
    updateHabitacio,
    updatePreus,
    updateParams,
    changeState,
    getPaisos,
    downloadTXT,
    downloadPDF,
    devolucio,
    createReservaAdmin,
    createExtra,
    updateExtra,
    createXec,
    updateXec,
    createCodi,
    getCodi,
    changeStateXec,
    deleteElement,
    codiCorrecte,
    createReservaXec,
    updatePersona,
    calendarisBooking,
    comprovarDisponibilitat,
    canviarHabitacio,
    downloadFactura,
    campsFactura,
    addConcepte,
    ferReserva,
    checkinReserva,
    checkin,
    checkinAdmin,
    reenviarCorreus,
    obtenirPreus,
    updateDescompte,
    llegirDNI,
    comprovarPersonaAPI,
    afegirPersonaAPI,
    checkinAdminLector,
    changeEmail,
    searchPersona,
    changeNotes,
    createReservaSenseCheckin,
    addDia,
};
