import React from "react";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout";
import LoginView from "./views/admin/LogInView";
import Home from "./views/public/Home";
import { Navigate } from "react-router-dom";
import ReservesView from "./views/admin/Reserves/ReservesView";
import ReservaView from "./views/admin/Reserves/ReservaView";
import CalendariView from "./views/admin/CalendariView";
import HabitacionsView from "./views/admin/HabitacionsView";
import UrlOK from "./views/public/Retorn/UrlOK";
import UrlKO from "./views/public/Retorn/UrlKO";
import PersonesView from "./views/admin/Persones/PersonesView";
import HomeView from "./views/admin/HomeView";
import ReservaAdd from "./views/admin/Reserves/ReservaAdd";
import CompraXec from "./views/public/CompraXec";
import CodisView from "./views/admin/Codis/CodisView";
import CodiView from "./views/admin/Codis/CodiView";
import GastaXec from "./views/public/GastaXec";
import CheckIn from "./views/public/CheckIn";
import FacturesAdmin from "./views/admin/Factures/FacturesAdmin";
import ConfigCalendari from "./views/admin/ConfCalendari/ConfCalendari";
import EstadistiquesView from "./views/admin/Estadistiques/Estadistiques";

const routes = (logged) => [
    {
        path: "/admin",
        element: logged ? <AdminLayout /> : <Navigate to="/login" />,
        children: [
            { path: "/admin", element: <HomeView /> },
            { path: "/admin/reserves", element: <ReservesView /> },
            { path: "/admin/calendari", element: <CalendariView /> },
            { path: "/admin/habitacions", element: <HabitacionsView /> },
            { path: "/admin/persones", element: <PersonesView /> },
            { path: "/admin/:key", element: <ReservaView /> },
            { path: "/admin/add", element: <ReservaAdd /> },
            { path: "/admin/codis", element: <CodisView /> },
            { path: "/admin/codis/:key", element: <CodiView /> },
            { path: "/admin/factures", element: <FacturesAdmin /> },
            { path: "/admin/configcalendari", element: <ConfigCalendari /> },
            { path: "/admin/estadistiques", element: <EstadistiquesView /> },
            // { path: '/*', element: <NotFound /> },
        ],
    },
    {
        path: "/",
        element: <PublicLayout />,
        children: [
            {
                path: "/login",
                element: !logged ? <LoginView /> : <Navigate to="/admin" />,
            },
            { path: "/", element: <Home /> },
            { path: "/compra", element: <CompraXec /> },
            { path: "/codi", element: <GastaXec /> },
            { path: "/codi/:key", element: <GastaXec /> },
            { path: "/exit/:id", element: <UrlOK /> },
            { path: "/error/:id", element: <UrlKO /> },
            { path: "/checkin", element: <CheckIn /> },
            { path: "/checkin/:key", element: <CheckIn /> },
        ],
    },
];

export default routes;
