import {
    Box,
    CircularProgress,
    Container,
    Fade,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import Title from "../../../components.js/Title";
import { get, getAdmin } from "../../../database/API";
import { ResponsiveLine } from "@nivo/line";
import { Calendar, Delete, Pause, UserCheck, UserPlus, Users } from "react-feather";
import theme from "../../../theme";
import { ResponsivePie } from "@nivo/pie";
import { GenereChart } from "./Charts/GenereChart";
import { ReservesChart } from "./Charts/ReservesChart";
import { NacionalitatChart } from "./Charts/NacionalitatChart";

const estats = [
    { titol: "Total", camp: "suma" },
    { titol: "Pendent de pagament", camp: "pendent_pagament" },
    { titol: "Pagat", camp: "pagat" },
    { titol: "Completat", camp: "completat" },
    { titol: "Cancel·lat", camp: "cancelat" },
    { titol: "Pendent de checkin", camp: "pendent_check" },
];

const EstadistiquesView = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [mode, setMode] = useState("reserva");
    const [hab, setHab] = useState("");
    const [habitacions, setHabitacions] = useState([]);
    const [nacionalitat, setNacionalitat] = useState([]);
    const [provincia, setProvincia] = useState([]);
    const [counts, setCounts] = useState();
    const [loading, setLoading] = useState(true);
    const [dataI, setDataI] = useState(moment().subtract("month", 1).format("YYYY-MM-DD"));
    const [dataF, setDataF] = useState(moment().format("YYYY-MM-DD"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const getH = async () => {
            const { resultat } = await get("habitacions");
            setHabitacions(resultat);
            setLoading(false);
        };

        getH();
    }, []);

    useEffect(() => {
        const getI = async () => {
            setLoading(true);
            const { resultat } = await getAdmin(`reservesEstadistiques?dataI=${dataI}&dataF=${dataF}&mode=${mode}&hab=${hab}`);
            let res = [];
            estats.map((estat) =>
                res.push({
                    id: estat.titol,
                    data: resultat.result.map((item) => {
                        return { x: moment(item.dateG).format("DD/MM/YYYY"), y: item?.[estat?.camp] ? Number(item?.[estat?.camp]) : 0 };
                    }),
                })
            );

            let espanya = 0;
            let franca = 0;
            let alemania = 0;
            let belgica = 0;
            let eeuu = 0;
            let italia = 0;
            let holanda = 0;
            let altres = 0;
            resultat?.personesPaisosCount?.map((i) => {
                if (i.nacionalitat === "España" || i.nacionalitat === "ESP" || i.nacionalitat === "Espanya" || i.nacionalitat === "ESPA") {
                    espanya = espanya + i.total;
                } else if (i.nacionalitat === "FRA" || i.nacionalitat === "Francia" || i.nacionalitat === "França") {
                    franca = franca + i.total;
                } else if (i.nacionalitat === "Alemania") {
                    alemania = alemania + i.total;
                } else if (i.nacionalitat === "Bélgica" || i.nacionalitat === "BEL") {
                    belgica = belgica + i.total;
                } else if (i.nacionalitat === "Estados Unidos" || i.nacionalitat === "USA") {
                    eeuu = eeuu + i.total;
                } else if (i.nacionalitat === "ITA" || i.nacionalitat === "Italia") {
                    italia = italia + i.total;
                } else if (i.nacionalitat === "NLD" || i.nacionalitat === "Netherlands" || i.nacionalitat === "Holanda") {
                    holanda = holanda + i.total;
                } else {
                    altres = altres + i.total;
                }
            });

            setNacionalitat([
                { id: "Espanya", value: espanya },
                { id: "França", value: franca },
                { id: "Alemanya", value: alemania },
                { id: "Bèlgica", value: belgica },
                { id: "USA", value: eeuu },
                { id: "Itàlia", value: italia },
                { id: "Holanda", value: holanda },
                { id: "Altres", value: altres },
            ]);

            let barcelona = 0;
            let albacete = 0;
            let bizkaia = 0;
            let castellon = 0;
            let girona = 0;
            let lleida = 0;
            let madrid = 0;
            let navarra = 0;
            let tarragona = 0;
            let valencia = 0;
            resultat?.personesProvinciaCount?.map((i) => {
                if (i.provincia === "BARCELONA" || i.provincia === "Barcelona" || i.provincia === "?AR?LONA" || i.provincia === "RARCELONA") {
                    barcelona = barcelona + i.total;
                } else if (i.provincia === "ALBACETE") {
                    albacete = albacete + i.total;
                } else if (i.provincia === "BIZKAIA" || i.provincia === "BTZKAIA") {
                    bizkaia = bizkaia + i.total;
                } else if (i.provincia === "CASTELLON") {
                    castellon = castellon + i.total;
                } else if (i.provincia === "GIRONA") {
                    girona = girona + i.total;
                } else if (i.provincia === "LLEIDA") {
                    lleida = lleida + i.total;
                } else if (i.provincia === "MADRID") {
                    madrid = madrid + i.total;
                } else if (i.provincia === "NAVARRA") {
                    navarra = navarra + i.total;
                } else if (i.provincia === "TARRAGONA") {
                    tarragona = tarragona + i.total;
                } else if (i.provincia === "VALENCIA" || i.provincia === "VALENCTA") {
                    valencia = valencia + i.total;
                }
            });

            setProvincia([
                { id: "Barcelona", value: barcelona },
                { id: "Albacete", value: albacete },
                { id: "Bizkaia", value: bizkaia },
                { id: "Castellón", value: castellon },
                { id: "Girona", value: girona },
                { id: "Lleida", value: lleida },
                { id: "Madrid", value: madrid },
                { id: "Navarra", value: navarra },
                { id: "Tarragona", value: tarragona },
                { id: "València", value: valencia },
            ]);

            console.log(resultat);
            setCounts(resultat);
            setData(res);
            setLoading(false);
        };

        getI();
    }, [dataI, dataF, mode, hab]);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Box>
                <Box pt={2} spacing={3}>
                    <Title title="Estadístiques" />
                    <Box my={4} />
                    <Box className={classes.block} mb={4}>
                        <Box display={"flex"} justifyContent={"space-between"} mb={3}>
                            <Box>
                                <Typography variant="h2">{hab ? habitacions?.find((i) => i.id === hab)?.nom : "Totes les habitacions"}</Typography>
                                <Typography variant="body1">
                                    Del {moment(dataI).format("L")} al {moment(dataF).format("L")}
                                </Typography>
                            </Box>
                            <Typography variant="body2">Mode: {mode === "creacio" ? "Per data de creació" : "Per data de la reserva"}</Typography>
                        </Box>
                        {loading ? (
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} py={2.75}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={3}>
                                <Grid item md={3} mt={2}>
                                    <TextField fullWidth type="date" value={dataI} onChange={(e) => setDataI(e.target.value)} name="dataI" label="Data inici" />
                                </Grid>
                                <Grid item md={3} mt={2}>
                                    <TextField fullWidth type="date" value={dataF} onChange={(e) => setDataF(e.target.value)} name="dataF" label="Data final" />
                                </Grid>
                                <Grid item md={3} mt={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>Habitació</InputLabel>
                                        <Select label="Habitació" value={hab ?? ""} onChange={(value) => setHab(value.target.value)} required>
                                            <MenuItem value={""}>Totes</MenuItem>
                                            {habitacions?.map((item) => (
                                                <MenuItem value={item.id}>{item.nom}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={mode}
                                            onChange={(e) => setMode(e.target.value)}
                                        >
                                            <FormControlLabel value="creacio" control={<Radio />} label="Per data de creació" />
                                            <FormControlLabel value="reserva" control={<Radio />} label="Per data de la reserva" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                    <Fade in={!loading}>
                        <Grid container spacing={4} mb={4}>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Reserves completades entre dates</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {Number(counts?.counts?.completat) + Number(counts?.counts?.pagat)}
                                            </Typography>
                                        </Box>
                                        <UserCheck size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Reserves totals entre dates</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {counts?.counts?.suma}
                                            </Typography>
                                        </Box>
                                        <Users size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Cancel·lades entre dates</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {counts?.counts?.cancelat}
                                            </Typography>
                                        </Box>
                                        <Delete size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Pendents pagament entre dates</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {Number(counts?.counts?.pendent_pagament)}
                                            </Typography>
                                        </Box>
                                        <Pause size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Pendents check-in entre dates</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {Number(counts?.counts?.pendent_check)}
                                            </Typography>
                                        </Box>
                                        <Pause size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Nous clients entre dates</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {Number(counts?.personesCount?.suma)}
                                            </Typography>
                                        </Box>
                                        <UserPlus size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Clients Totals</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {Number(counts?.personesCountTotal?.suma)}
                                            </Typography>
                                        </Box>
                                        <Users size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box className={classes.block}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box>
                                            <Typography variant="body2">Reserves Totals</Typography>
                                            <Typography variant="h2" fontSize={35}>
                                                {Number(counts?.reservesCountTotal?.suma)}
                                            </Typography>
                                        </Box>
                                        <Calendar size={30} color={theme.palette.secondary.main} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Fade>
                    <Fade in={!loading}>
                        <Box className={classes.block} mb={4}>
                            <Box my={3} />
                            <Typography variant="body2">Reserves per estat</Typography>
                            <Box height={600}>
                                <ReservesChart data={data} />
                            </Box>
                        </Box>
                    </Fade>
                    <Fade in={!loading}>
                        <Grid container spacing={3}>
                            <Grid item md={4}>
                                <Box className={classes.block}>
                                    <Typography variant="body2">Gènere</Typography>
                                    <Box height={400}>
                                        <GenereChart data={counts} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className={classes.block}>
                                    <Typography variant="body2">País</Typography>
                                    <Box height={400}>
                                        <NacionalitatChart data={nacionalitat} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className={classes.block}>
                                    <Typography variant="body2">Província</Typography>
                                    <Box height={400}>
                                        <NacionalitatChart data={provincia} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Fade>
                </Box>
            </Box>
        </Container>
    );
};

export default EstadistiquesView;

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 40,
        paddingBottom: 80,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));
